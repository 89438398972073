.ab-button-wrapper {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.ab-download-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: '100%';
  height: 44px;
  background-color: #ff9900;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 2px;
  overflow: hidden;
  transition: color 1s ease;
  padding: '12px 16px';
}

.ab-download-btn:hover {
  color: #4ba8ba;
}

.ab-text {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ab-hover-bg {
  position: absolute;
  bottom: -100px;
  left: 50%;
  width: 100%;
  height: 200px;
  background-color: #222;
  transform: translateX(-50%) scale(0);
  border-radius: 40%;
  z-index: 1;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ab-download-btn:hover .ab-hover-bg {
  transform: translateX(-50%) scale(2.5);
}

.ab-download-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}
