@keyframes slide {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.logos {
  overflow: hidden;
  white-space: nowrap;
  margin: 10px 0px;
  height: 100%;
}
.logos:hover .logos-slide {
  animation-play-state: paused;
}
.logos-slide {
  display: flex;
  animation: 20s slide infinite linear;
}
